<template>
  <!-- In progress -->
  <div v-if="inprogress" class="text-center">
    <h2>{{ inprogresstxt }}</h2>
    <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
  </div>

  <div v-else class="row">
    <div class="col-md-1">
      <p-button class="abit-down" round outline v-on:click="prevPage()">
        <i slot="label" class="nc-icon nc-minimal-left"></i>
        {{ $t('back') }}
      </p-button>
    </div>

    <div class="col-md-11">
      <h4 class="title">{{ $t('mattxt') }}: {{ mat_server }}</h4>
    </div>

    <div class="col-12 text-center" v-if="alert_me">
      <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
        {{ alert_msg }}
      </div>
    </div>

    <div class="col-md-12 card">
      <!--
      <div class="card-header">
        <div class="category">Extended tables</div>
      </div>
      -->
      <div class="card-body row">

        <div class="col-sm-2">
          <el-select class="select-default" v-model="sortby.column" :placeholder="sortbyTxt"
            v-on:change="sortByCol">
            <el-option class="select-default"
              v-for="item in sortby.columns"
              :key="item.prop" :label="item.label" :value="item.prop">
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-2">
          <el-select
            class="select-default" v-model="pagination.perPage" :placeholder="perpageTxt">
            <el-option class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>

        <div class="col-sm-2">
          <el-select
            class="select-default" v-model="sortby.order" :placeholder="sortbyOrder"
            v-on:change="sortByCol">
            <el-option class="select-default"
              v-for="item in sortby.orders"
              :key="item.prop" :label="item.label" :value="item.prop">
            </el-option>
          </el-select>
        </div>


        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :prop="column.prop"
                             :label="column.label">
            </el-table-column>

          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Button, Modal} from 'src/components/UIComponents'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(require('vue-moment'))

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  let bills = [];
  let timeoutcount = 1500;

  export default {
    components: {
      PPagination, [Button.name]: Button, Modal
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        perpageTxt: this.$t('perpage'),
        sortbyTxt: this.$t('sortby'),
        sortbyOrder: this.$t('sortbyorder'),
        colSort: 'last_update',
        inprogress: true,
        actionlbl: this.$t('action'),
        alert_me: false,
        alert_msg: null,
        inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('mattxt') +". "+ this.$t('pleasewait'),
        alert_class: 'text-danger',
        modalparams: false,
        alert_me_modal: false,
        mat_server: null,
        sortby: {
          column: 'last_update',
          order: 'asc',
          orders: [
            {
              prop: 'asc',
              label: this.$t('ascending')
            },
            {
              prop: 'desc',
              label: this.$t('descending')
            }
          ],
          columns: [
            {
              prop: 'userid',
              label: this.$t('userid')
            },
            {
              prop: 'first_name',
              label: this.$t('firstname')
            },
            {
              prop: 'last_name',
              label: this.$t('lastname')
            },
            {
              prop: 'strat_name',
              label: this.$t('strategytxt')
            },
            {
              prop: 'account',
              label: this.$t('account')
            },
            {
              prop: 'balance',
              label: this.$t('balance')
            },
            {
              prop: 'profit',
              label: this.$t('profit')
            },
            {
              prop: 'last_update',
              label: this.$t('date')
            },
          ]
        },
        pagination: {
          perPage: 5,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['userid', 'first_name', 'last_name', 'strat_name', 'account'],
        tableColumns: [
          {
            prop: 'userid',
            label: this.$t('userid')
          },
          {
            prop: 'first_name',
            label: this.$t('firstname')
          },
          {
            prop: 'last_name',
            label: this.$t('lastname')
          },
          {
            prop: 'strat_name',
            label: this.$t('strategytxt')
          },
          {
            prop: 'account',
            label: this.$t('account')
          },
          {
            prop: 'balance',
            label: this.$t('balance')
          },
          {
            prop: 'profit',
            label: this.$t('profit')
          },
          {
            prop: 'last_update',
            label: this.$t('date')
          },
          {
            prop: 'platform',
            label: this.$t('platformtxt')
          },
        ],
        tableData: [],
        modalconfirm: false,
        passid: null,
        did: null
      }
    },
    methods: {
      closeModalAlert() {
        this.modalconfirm = false;
      },
      closeModalParams() {
        this.modalparams = false;
        this.accountnum = null;
        this.accountsettings = null;
        //this.did = null;
      },
      completedTask() {
        this.closeModalAlert();

        this.alert_me = true;
        this.alert_msg = this.$t('goodjob');
        this.alert_class = 'text-success';
        this.did = null;
        // Reload and close
        this.$getParamsChanges().then(this.loadIt, this.failop);
        setTimeout(this.resetAlert, timeoutcount);
      },
      confirmedTaskDone() {
        this.$paramsChangesDone(this.did, user_data.email).then(this.completedTask, this.failop);
      },
      changes(id, account) {
        let cidx = this.tableData.findIndex(t => parseInt(t.id) === parseInt(id));
        if (cidx >= 0) {
          this.did = id;
          this.accountnum = account;
          this.accountsettings = this.tableData[cidx].settings;
          this.accountsettings['allButTheseChanged'] = this.tableData[cidx].allButTheseChanged;
          this.accountsettings['onlyTheseChanged'] = this.tableData[cidx].onlyTheseChanged;
          console.log(this.tableData[cidx]);
          this.tradedAssetsMatching = this.tableData[cidx].tradedAssetsMatching;
          this.assetSuffix = this.tableData[cidx].assetSuffix;
          this.modalparams = true;
        }
      },
      done() {
        if (this.did) {
          this.closeModalParams();
        }
        this.modalconfirm = true;
      },
      resetAlert() {
        this.alert_me = false;
        this.alert_msg = null;
        this.alert_class = 'text-danger';
      },
      loadIt(resp) {
        for(let i=0;i < resp.data.followers.length;i++) {
          let s = resp.data.followers[i];
          s['follower'] = true;
          s['last_update'] = this.$moment(s['last_update'] * 1000).format("YYYY-MM-DD HH:mm");
          this.tableData.push(s);
        }
        for(let i=0;i < resp.data.masters.length;i++) {
          let s = resp.data.masters[i];
          s['follower'] = false;
          s['last_update'] = this.$moment(s['last_update'] * 1000).format("YYYY-MM-DD HH:mm");
          this.tableData.push(s);
        }
        this.inprogress = false;
      },
      prevPage() {
        window.history.back();
      },
      sortItNum (a, b) {
        if (this.sortby.order == 'asc') {
          return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (this.sortby.order == 'asc') {
          if (a[this.colSort] < b[this.colSort])
            return -1;
          if (a[this.colSort] > b[this.colSort])
            return 1;
        }
        if (b[this.colSort] < a[this.colSort])
          return -1;
        if (b[this.colSort] > a[this.colSort])
          return 1;
        return 0;
      },
      sortByCol() {
        //console.log(this.sortby);
        this.colSort = this.sortby.column;
        if (this.sortby.column !== 'balance' && this.sortby.column !== 'profit') {
          this.tableData.sort(this.sortIt);
        }
        else {
          this.tableData.sort(this.sortItNum);
        }
      },
      failop (error) {
        this.alert_me = true;
        this.alert_msg = error;
        this.alert_class = 'text-danger';
      }
    },
    mounted () {
      this.mat_server = this.$route.params.mat_server;
      this.$getMATs(this.$route.params.mat_server).then(this.loadIt, this.failop);
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
  }
</script>
<style>
  .abit-down {
    top: 15px;
    position: relative;
  }
</style>
